import styled from 'styled-components';

export default styled.div`
  padding: 116px 0;
  background-color: ${props => props.backgroundColor};
  .container {
    max-width: 970px;

    .row {
      justify-content: center;

      h2 {
        margin-bottom: 16px;
      }

      .subhead {
        margin-bottom: 64px;
      }

      figure {
        width: 624px;
        height: 490px;
        margin-bottom: 64px;
      }

      h4 {
        text-align: center;
        color: var(--text-color);
      }
    }
  }
`;
